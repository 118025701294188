var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { title: "Multiple Select" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card-code",
                {
                  staticClass: "border",
                  attrs: { title: "Basic Multiple Select" },
                  scopedSlots: _vm._u([
                    {
                      key: "code",
                      fn: function() {
                        return [
                          _vm._v(" " + _vm._s(_vm.codeVueMultiBasic) + " ")
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-card-text", [
                    _c("span", [_vm._v("Equivalent to the ")]),
                    _c("code", [_vm._v("multiple")]),
                    _c("span", [_vm._v(" attribute on a ")]),
                    _c("code", [_vm._v("<select>")]),
                    _c("span", [_vm._v(" input.")])
                  ]),
                  _c("v-select", {
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      multiple: "",
                      label: "title",
                      options: _vm.option
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card-code",
                {
                  staticClass: "border",
                  attrs: { title: "Select with Icon" },
                  scopedSlots: _vm._u([
                    {
                      key: "code",
                      fn: function() {
                        return [
                          _vm._v(" " + _vm._s(_vm.codeVueMultiIcon) + " ")
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-card-text", [
                    _c("span", [
                      _vm._v(
                        "The current option within the dropdown, contained within "
                      )
                    ]),
                    _c("code", [_vm._v("<li>")]),
                    _c("span", [_vm._v(".")])
                  ]),
                  _c("v-select", {
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      multiple: "",
                      options: _vm.books,
                      label: "title"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function(ref) {
                          var title = ref.title
                          var icon = ref.icon
                          return [
                            _c("feather-icon", {
                              staticClass: "align-middle mr-25",
                              attrs: { icon: icon, size: "16" }
                            }),
                            _c("span", [_vm._v(" " + _vm._s(title))])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selected1,
                      callback: function($$v) {
                        _vm.selected1 = $$v
                      },
                      expression: "selected1"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card-code",
                {
                  staticClass: "border",
                  attrs: { title: "vue-select pushTags" },
                  scopedSlots: _vm._u([
                    {
                      key: "code",
                      fn: function() {
                        return [_vm._v(" " + _vm._s(_vm.codeMultiPush) + " ")]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " To allow input that's not present within the options, set the taggable prop to true. "
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      multiple: "",
                      taggable: "",
                      "push-tags": "",
                      placeholder: "Add Options",
                      label: "title"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card-code",
                {
                  staticClass: "border mb-0",
                  attrs: { title: "Limiting the Number of Selections" },
                  scopedSlots: _vm._u([
                    {
                      key: "code",
                      fn: function() {
                        return [
                          _vm._v(" " + _vm._s(_vm.codeVueSelectionLimit) + " ")
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-card-text", [
                    _c("code", [_vm._v("selectable")]),
                    _c("span", [
                      _vm._v(
                        " can also be used a bit more creatively to limit the number selections that can be made within the component. In this case, the user can select any author, but may only select a maximum of three books. "
                      )
                    ])
                  ]),
                  _c("v-select", {
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      multiple: "",
                      placeholder: "Choose up to 3 books!",
                      label: "title",
                      options: _vm.books,
                      selectable: function() {
                        return _vm.selected2.length < 3
                      }
                    },
                    model: {
                      value: _vm.selected2,
                      callback: function($$v) {
                        _vm.selected2 = $$v
                      },
                      expression: "selected2"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-card-code",
                {
                  staticClass: "border mb-0",
                  attrs: { title: "Vue-Select direction" },
                  scopedSlots: _vm._u([
                    {
                      key: "code",
                      fn: function() {
                        return [_vm._v(" " + _vm._s(_vm.codeVueDir) + " ")]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-card-text", [
                    _c("span", [_vm._v("Sets RTL support. Accepts ")]),
                    _c("code", [_vm._v("ltr, rtl, auto")]),
                    _vm._v(".")
                  ]),
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "some-radios",
                            value: "ltr",
                            inline: ""
                          },
                          model: {
                            value: _vm.dir,
                            callback: function($$v) {
                              _vm.dir = $$v
                            },
                            expression: "dir"
                          }
                        },
                        [_vm._v(" LRT ")]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "some-radios",
                            value: "rtl",
                            inline: ""
                          },
                          model: {
                            value: _vm.dir,
                            callback: function($$v) {
                              _vm.dir = $$v
                            },
                            expression: "dir"
                          }
                        },
                        [_vm._v(" RTL ")]
                      )
                    ],
                    1
                  ),
                  _c("v-select", {
                    attrs: {
                      dir: _vm.dir,
                      multiple: "",
                      label: "title",
                      options: _vm.books
                    },
                    model: {
                      value: _vm.selected3,
                      callback: function($$v) {
                        _vm.selected3 = $$v
                      },
                      expression: "selected3"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }